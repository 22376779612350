import React from 'react';
import {
  Router as DefaultRouter,
  Route,
  Switch,
  StaticRouter,
} from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@@/history';
import RendererWrapper0 from 'D:/Go_WorkSpace/src/pd.bydeurope.com/antd/src/pages/.umi-production/LocaleWrapper.jsx';
import _dvaDynamic from 'dva/dynamic';

const Router = require('dva/router').routerRedux.ConnectedRouter;

const routes = [
  {
    path: '/',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__BlankLayout" */ '../../layouts/BlankLayout'),
          LoadingComponent: require('D:/Go_WorkSpace/src/pd.bydeurope.com/antd/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/BlankLayout').default,
    routes: [
      {
        path: '/user',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "layouts__UserLayout" */ '../../layouts/UserLayout'),
              LoadingComponent: require('D:/Go_WorkSpace/src/pd.bydeurope.com/antd/src/components/PageLoading/index')
                .default,
            })
          : require('../../layouts/UserLayout').default,
        routes: [
          {
            name: 'login',
            path: '/user/login',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__user__login__model.ts' */ 'D:/Go_WorkSpace/src/pd.bydeurope.com/antd/src/pages/user/login/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__user__login" */ '../user/login'),
                  LoadingComponent: require('D:/Go_WorkSpace/src/pd.bydeurope.com/antd/src/components/PageLoading/index')
                    .default,
                })
              : require('../user/login').default,
            exact: true,
          },
        ],
      },
      {
        path: '/',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "layouts__BasicLayout" */ '../../layouts/BasicLayout'),
              LoadingComponent: require('D:/Go_WorkSpace/src/pd.bydeurope.com/antd/src/components/PageLoading/index')
                .default,
            })
          : require('../../layouts/BasicLayout').default,
        Routes: [require('../Authorized').default],
        authority: ['6', '5', '4', '3'],
        routes: [
          {
            path: '/',
            name: 'home',
            icon: 'home',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Home" */ '../Home'),
                  LoadingComponent: require('D:/Go_WorkSpace/src/pd.bydeurope.com/antd/src/components/PageLoading/index')
                    .default,
                })
              : require('../Home').default,
            exact: true,
          },
          {
            path: '/projects',
            name: 'projects',
            icon: 'appstore',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__projects__model.ts' */ 'D:/Go_WorkSpace/src/pd.bydeurope.com/antd/src/pages/projects/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__projects" */ '../projects'),
                  LoadingComponent: require('D:/Go_WorkSpace/src/pd.bydeurope.com/antd/src/components/PageLoading/index')
                    .default,
                })
              : require('../projects').default,
            exact: true,
          },
          {
            path: '/databases',
            name: 'databases',
            icon: 'database',
            routes: [
              {
                icon: 'database',
                name: 'databases',
                path: '/databases/base',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../databases/database'),
                      LoadingComponent: require('D:/Go_WorkSpace/src/pd.bydeurope.com/antd/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../databases/database').default,
                exact: true,
              },
              {
                icon: 'car',
                name: 'vehicles',
                path: '/databases/vehicles',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__vehicles__model.ts' */ 'D:/Go_WorkSpace/src/pd.bydeurope.com/antd/src/pages/vehicles/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../vehicles'),
                      LoadingComponent: require('D:/Go_WorkSpace/src/pd.bydeurope.com/antd/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../vehicles').default,
                exact: true,
              },
              {
                hideInMenu: true,
                name: 'vehicle_configuration',
                path: '/databases/vehicle/detail/:id/:name',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__vehicles__model.ts' */ 'D:/Go_WorkSpace/src/pd.bydeurope.com/antd/src/pages/vehicles/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../vehicles/VehicleDetail'),
                      LoadingComponent: require('D:/Go_WorkSpace/src/pd.bydeurope.com/antd/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../vehicles/VehicleDetail').default,
                exact: true,
              },
            ],
          },
          {
            path: '/system',
            name: 'System',
            icon: 'setting',
            authority: ['6'],
            routes: [
              {
                name: 'Users',
                path: '/system/users',
                authority: ['6'],
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__system__user__model.ts' */ 'D:/Go_WorkSpace/src/pd.bydeurope.com/antd/src/pages/system/user/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../system/user'),
                      LoadingComponent: require('D:/Go_WorkSpace/src/pd.bydeurope.com/antd/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../system/user').default,
                exact: true,
              },
            ],
          },
          {
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__404" */ '../404'),
                  LoadingComponent: require('D:/Go_WorkSpace/src/pd.bydeurope.com/antd/src/components/PageLoading/index')
                    .default,
                })
              : require('../404').default,
            exact: true,
          },
        ],
      },
      {
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__404" */ '../404'),
              LoadingComponent: require('D:/Go_WorkSpace/src/pd.bydeurope.com/antd/src/components/PageLoading/index')
                .default,
            })
          : require('../404').default,
        exact: true,
      },
    ],
  },
];
window.g_routes = routes;
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen() {}

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    // dva 中 history.listen 会初始执行一次
    // 这里排除掉 dva 的场景，可以避免 onRouteChange 在启用 dva 后的初始加载时被多执行一次
    const isDva =
      history.listen
        .toString()
        .indexOf('callback(history.location, history.action)') > -1;
    if (!isDva) {
      routeChangeHandler(history.location);
    }
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return (
      <RendererWrapper0>
        <Router history={history}>{renderRoutes(routes, props)}</Router>
      </RendererWrapper0>
    );
  }
}
