import { Effect } from 'dva';
import { Reducer } from 'redux';
import {
  copyFrom,
  fetchConfigVersion,
  fetchSpecConfig, fetchSpecConfigLogs,
  fetchTopSpec,
  removeSpecConfig,
  saveSpecConfig,
  submitConfigVersion,
} from '@/services/specconfig';
import { Tab } from './specconfig.d';
import { deleteFileItem } from "@/services/databases";
import { getRowdataById } from "@/utils/tree";

export interface SpecConfigStateType {
  configs: any;
  tabData: Tab[],
}

export interface SpecConfigType {
  namespace: string;
  state: SpecConfigStateType;
  effects: {
    // save spec config
    fetchSpecByParent: Effect; // 获取tabs数据
    fetchSpecByProject: Effect;
    saveSpecConfig: Effect;
    removeSpecConfig: Effect;
    fetchSpecConfig: Effect;
    fetchSpecConfigVersion: Effect;
    submitVersion: Effect;
    fetchSpecConfigLogs: Effect;
    copyFrom: Effect;
    removeFileItem: Effect;//删除配置单files
  },
  reducers: {
    save: Reducer
    saveTabData: Reducer // 保存specification, body等tab
    editSpecConfig: Reducer
    saveSpecConfigs: Reducer
  }
}

// @ts-ignore
const Model: SpecConfigType = {
  namespace: 'specconfig',
  state: {
    configs: {},
    tabData: [],
  },
  effects: {
    * fetchSpecByParent({ payload }, { call, put }) {
      // 项目顶部配置
      const response = yield call(fetchTopSpec, payload);
      if (response.data) {
        yield put({
          type: 'saveTabData',
          payload: response.data,
        });
      }
    },
    * fetchSpecByProject({ payload }, { call, put }) {
      const response = yield call(fetchTopSpec, payload);
      if (response.data) {
        yield put({
          type: 'saveTabData',
          payload: response.data,
        });
      }
    },
    // spec config
    * fetchSpecConfig({ payload, success }, { call, put }) {
      const response = yield call(fetchSpecConfig, payload);
      if (response.success) {
        console.log(response.data)
        for (let i = 0; i < response.data.length; i += 1) {
           for (let j = 0; j < response.data[i].files.length; j += 1) {
             response.data[i].files[j].url = decodeURIComponent(response.data[i].files[j].url);
             // eslint-disable-next-line max-len
             response.data[i].files[j].thumb_url = decodeURIComponent(response.data[i].files[j].thumb_url);
           }
        }
        if (success) {
          success(response.data);
        }
        yield put({
          type: 'saveSpecConfigs',
          payload: response.data,
        });
      }
    },

    * saveSpecConfig({ payload, success }, { call }) {
      const response = yield call(saveSpecConfig, payload);
      if (response) {
        if (response.success) {
          if (success) {
            success(response.data);
          }
        }
      }
    },
    * removeSpecConfig({ payload, success }, { call }) {
      const response = yield call(removeSpecConfig, payload);
      if (response) {
        if (success) {
          success(response.data);
        }
      }
    },
    * fetchSpecConfigVersion({ payload, success }, { call }) {
      const response = yield call(fetchConfigVersion, payload);
      if (response) {
        success(response.data);
      }
    },
    * fetchSpecConfigLogs({ payload, success }, { call }) {
      const response = yield call(fetchSpecConfigLogs, payload);
      if (response) {
        success(response.data);
      }
    },
    * submitVersion({ payload, success }, { call }) {
      const response = yield call(submitConfigVersion, payload);
      if (response) {
        success(response.data);
      }
    },
    * copyFrom({ payload, success }, { call }) {
      const response = yield call(copyFrom, payload);
      if (response) {
        success(response.data);
      }
    },
    * removeFileItem({ payload, success }, { call, put }) {
      const {
        file: { uid, target_id: targetId },
      } = payload;
      const response = yield call(deleteFileItem, {
        uid,
      });
      // console.log(response,'response')
      if (response.success) {
        if (success) success();
        yield put({
          type: 'editSpecConfig',
          payload: {
            id: targetId,
            uid,
          },
        });
      }
    },
  },
  reducers: {
    /**
     * yield put({
          type: 'save',
          payload: {
            tabData: response.data,
            ownerId: payload.owner_id,
            tabId: payload.tab_id,
          },
        });
     */
    save(state, action) {
      if (action.payload.tabData) {
        const { ownerId, tabId, tabData } = action.payload;
        const { configs } = state;
        const key = `${ownerId}_${tabId}`;
        if (tabData && Array.isArray(tabData) && tabData.length > 0) {
          if (configs[key]) {
            // 只添加一个
            configs[key].push(...tabData);
          } else {
            configs[key] = [...tabData];
          }
          return {
            ...state,
            configs: { ...configs },
          };
        }
      }

      return {
        ...state,
      };
    },
    saveTabData(state, action) {
      return {
        ...state,
        tabData: action.payload,
      };
    },
    saveSpecConfigs(state, action) {
      console.log(action.payload)
      for (let i = 0; i < action.payload.length; i += 1) {
        action.payload[i].filep = action.payload[i].files;
        console.log(action.payload[i].files)
        if (action.payload[i].files) {
          action.payload[i].files = [];
        }
      }
      return {
        ...state,
        configs: action.payload,
      };
    },
    editSpecConfig(state, action) {
      const { payload: { id, uid } } = action;
      // console.log(state, 'state----project------')
      console.log(state, action, 'state,action----project------')
      const list = getRowdataById(id, state.configs);
      console.log(uid, list, list.filep, '================')
      if (uid && list && list.filep) {
        list.filep = list.filep.filter((item: any) => item.uid !== uid);
        // list.files = list.files.filter((item: any) => item.uid !== uid);
      }
      return {
        ...state,
         configs: state.configs,
      };

    },
  },
};
export default Model;
