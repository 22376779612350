import React from 'react';
import { connect } from 'dva';
import pathToRegexp from 'path-to-regexp';
import Authorized from '@/utils/Authorized';
import { ConnectProps, ConnectState, Route, UserModelState } from '@/models/connect';
import { Redirect } from 'umi';

interface AuthComponentProps extends ConnectProps {
  user: UserModelState;
}
const getRouteAuthority = (path: string, routeData: Route[], parent: any) => {
  let authorities: string[] = [];
  if (parent.authority instanceof Array) {
    authorities.push(...parent.authority);
  } else if (typeof parent.authority === 'string') {
    authorities.push(parent.authority);
  }

  authorities = [...new Set(authorities)];
  routeData.forEach(route => {
    // match prefix

    if (pathToRegexp(`${route.path}(.*)`).test(path)) {
      // exact match
      if (route.path === path) {
        if (route.authority instanceof Array) {
          authorities.push(...route.authority);
        } else if (typeof route.authority === 'string') {
          authorities.push(route.authority);
        }
      }

      route.authority = authorities;
      // get children authority recursively
      if (route.routes) {
        authorities = getRouteAuthority(path, route.routes, route) || authorities;
      }
    }
  });
  authorities = [...new Set(authorities)];
  return authorities;
};

const AuthComponent: React.FC<AuthComponentProps> = ({
                                                       children,
                                                       route = {
                                                         routes: [],
                                                       },
                                                       location = {
                                                         pathname: '',
                                                       },
                                                       user,
                                                     }) => {
  const { currentUser } = user;
  const { routes = [] } = route;
  const isLogin = currentUser && currentUser.name;
  // ./umi/routes 会读取每个页面时，会包含routes


  return (
    <Authorized
      authority={getRouteAuthority(location.pathname, routes, route) || ''}
      noMatch={isLogin ? <Redirect to="/exception/403"/> : <Redirect to="/user/login"/>}
    >
      {children}
    </Authorized>
  );
};

export default connect(({ user }: ConnectState) => ({
  user,
}))(AuthComponent);
