import request from '@/utils/request';

export async function saveSpecConfig(params: any) {
  return request('/api/specdata/config/save', {
    method: 'post',
    params,
  });
}

export async function removeSpecConfig(params: any) {
  return request('/api/specdata/config/remove', {
    method: 'post',
    params,
  });
}

// vehicle configuration services
export async function fetchSpecConfig(params: any) {
  return request('/api/specdatas/specconfig/list', {
    method: 'get',
    params,
  });
}
export async function fetchProjectTop(params: any) {
  return request('/api/specdatas/projects', {
    method: 'get',
    params,
  });
}

export async function fetchTopSpec(params: any) {
  return request('/api/specdatas/byparent', {
    method: 'get',
    params,
  });
}

export async function submitConfigVersion(params: any) {
  return request('/api/specdatas/specconfig/submitversion', {
    method: 'post',
    params,
  });
}
export async function fetchConfigVersion(params: any) {
  return request('/api/specdatas/specconfig/getcurrentversion', {
    method: 'get',
    params,
  });
}
export async function fetchSpecConfigLogs(params: any) {
  return request('/api/specdatas/specconfig/getversionlogs', {
    method: 'get',
    params,
  });
}
export async function copyFrom(params: any) {
  return request('/api/specdatas/specconfig/copyfrom', {
    method: 'post',
    params,
  });
}
