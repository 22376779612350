import { Effect } from 'dva';
import { query as querySpecData } from '@/services/specdata';

export interface SpecData {
  name: ''
}

export interface SpecDataModelState {
  data: Partial<SpecData>,
}

export interface SpecDataModelType {
  namespace: 'specdata',
  state: SpecDataModelState,
  effects: {
    fetch: Effect;
  }
}

const SpecDataModel: SpecDataModelType = {
  namespace: 'specdata',
  state: {
    data: {
      name: '',
    },
  },
  effects: {
    * fetch(_, { call, put }) {
      const response = yield call(querySpecData);
      console.log(response);
    },
  },
};

export default SpecDataModel;
