export default {
  'menu.home': 'Home',
  'menu.projects': 'Projects',
  'menu.databases': 'Databases',
  'menu.databases.databases': 'Primary Data',
  'menu.databases.vehicles': 'Vehicles',
  'menu.databases.vehicle_configuration': 'Vehicle configuration',
  'menu.System': 'System',
  'menu.System.Users': 'Users',
  'menu.System.Setting': 'Setting',
};
