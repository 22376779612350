import request from '@/utils/request';
import { Effect } from 'dva';
import { Reducer } from 'redux';

export interface IdName {
  id: number,
  name: string,
  role?: number,
}


export interface ComboModelState {
  departments?: IdName[],
  groups?: IdName[],
  roles?: IdName[],
  userStates?: IdName[],
  value?: any
}

export interface ComboModelType {
  namespace: string;
  state: ComboModelState;
  effects: {
    fetchDepartments: Effect;
    fetchGroups: Effect;
    fetchRoles: Effect;
    fetchUserStates: Effect; // normal or pause
  },
  reducers: {
    saveDepartments: Reducer;
    saveGroups: Reducer;
    saveRoles: Reducer;
    saveUserStates: Reducer; //
  }
}


/**
 * const cache = {
  departments: false,
  group: false,
};
 */


const Combo: ComboModelType = {
  namespace: 'combo',
  state: {},
  effects: {
    * fetchDepartments(_, { call, put }) {
      // if (cache.departments) return;
      const response = yield query('/api/departments');
      yield put({
        type: 'saveDepartments',
        payload: response,
      });
    },
    * fetchGroups(_, { call, put }) {
      // if (cache.departments) return;
      const response = yield query('/api/groups');
      yield put({
        type: 'saveGroups',
        payload: response,
      });
    },
    * fetchRoles(_, { call, put }) {
      const response = yield query('/api/roles');
      yield put({
        type: 'saveRoles',
        payload: response,
      });
    },
    * fetchUserStates(_, { call, put }) {
      const response = yield query('/api/users/states');
      yield put({
        type: 'saveUserStates',
        payload: response,
      });
    },
  },
  reducers: {
    saveDepartments(state: ComboModelState, action: any) {
      /**
       * if (action.payload.data.length > 0) {
        cache.departments = true;
      }
       */

      return {
        ...state,
        departments: action.payload.data,
      };
    },
    saveGroups(state: ComboModelState, action: any) {
      /**
       *  if (action.payload.data.length > 0) {
        cache.group = true;
      }
       */

      return {
        ...state,
        groups: action.payload.data,
      };
    },
    saveRoles(state: ComboModelState, action: any) {
      return {
        ...state,
        roles: action.payload.data,
      };
    },
    saveUserStates(state: ComboModelState, action: any) {
      return {
        ...state,
        userStates: action.payload.data,
      };
    },
  },
};

async function query(url: string): Promise<any> {
  return request(url);
}


export default Combo;
