// 获取父级id
const getTreeParentKey = (key: string, tree: any): any => {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children && node.children.length > 0) {
      // eslint-disable-next-line eqeqeq
      if (node.children.some((item: { id: string; }) => item.id == key)) {
        parentKey = `${node.id}`;
      } else if (getTreeParentKey(key, node.children)) {
        parentKey = getTreeParentKey(key, node.children);
      }
    }
  }
  return parentKey;
};
const getTreeParent = (key: string | number, tree: any): any => {
  let parentNode;
  if (!Array.isArray(tree)) {
    tree = [tree];
  }
  console.log(key, tree);
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];

    if (node.children && node.children.length > 0) {
      // eslint-disable-next-line eqeqeq
      if (node.children.some((item: { id: string; }) => item.id == key)) {
        parentNode = node;
        return parentNode;
      }
      parentNode = getTreeParent(key, node.children);
      if (parentNode) {
        return parentNode;
      }
    }
  }
  return parentNode;
};

// 根据key 返回node信息
const getTreeItem = (key: any, value: any, tree: any): any => {
  const stack = [];
  let node;
  let i;
  stack.push(tree);

  while (stack.length > 0) {
    node = stack.shift();

    // eslint-disable-next-line eqeqeq
    if (node[key] == value) {
      return node;
    }

    if (node.children && node.children.length) {
      for (i = 0; i < node.children.length; i += 1) {
        stack.push(node.children[i]);
      }
    }
  }
  return null;
};
// 获取children 下的names
const getChildrenTreeItem = (key: any, value: any, tree: any, type:any, rname:any): any => {
  let node1 = [];
  let node = getTreeItem(key, value, tree);
  for (let i = 0; i < node.children.length; i++) {
    let isAccord = true;
    if (type !== undefined && node.children[i].vehicle_ids != '' && node.children[i].vehicle_ids !== undefined) {
      const vIds = node.children[i].vehicle_ids.split(',');
      isAccord = false;
      for (let j = 0; j < vIds.length; j++) {
        if (vIds[j] == type) {
          isAccord = true;
          break;
        }
      }
    }
    if (isAccord) {
      // @ts-ignore
      // node1.push({ option_id: node.children[i].id, option_name: node.children[i].name })
      node1.push(node.children[i].name)
      //  node1.push({ option_id: node.children[i].id, option_name: node.children[i].name })
    }
  }
  // let result = [
  //   {
  //     name: rname,
  //     children: [
  //       node1,
  //     ],
  //   },
  //
  // ];
  return node1;
};
// 按name搜索, 返回key, treeNode
const searchTreeNodes = (key: string, value: string, tree: any): any => {
  const result = [];
  const stack = [];
  let node;
  let i;
  // @ts-ignore
  stack.push(tree);
  while (stack.length > 0) {
    node = stack.shift();
    if (node[key].indexOf(value) > -1) {
      // @ts-ignore
      result.push(node);
    }

    if (node.children && node.children.length) {
      for (i = 0; i < node.children.length; i += 1) {
        // @ts-ignore
        stack.push(node.children[i]);
      }
    }
  }
  return result;
};

const addTreeNode = (parentId: any, value: any, tree: any): any => {
  const stack = [];
  let node;
  let i;
  stack.push(tree);

  let vals: any[] = [];
  if (Array.isArray(value)) {
    vals = value;
  } else {
    vals = [value];
  }
  console.log(vals);
  while (stack.length > 0) {
    node = stack.shift();
    // eslint-disable-next-line eqeqeq
    if (node.id == parentId) {
      if (node.children) {
        if (vals.length === 1) {
          node.children.splice(vals[0].pos, 0, vals[0])
        } else {
          node.children.push(...vals);
        }

      } else {
        node.children = [...vals];
      }

      return tree;
    }

    if (node.children && node.children.length) {
      for (i = 0; i < node.children.length; i += 1) {
        stack.push(node.children[i]);
      }
    }
  }
  return tree;
};
// eslint-disable-next-line max-len
const operateTreeNodeById = (id: string | number, tree: any, operator: (currentNode: any, parentNode: any) => any): any => {
  const stack = [];
  let node;
  // @ts-ignore
  stack.push(tree);
  const cache = {};
  while (stack.length > 0) {
    node = stack.shift();
    // eslint-disable-next-line eqeqeq
    if (node.id == id) {
      return operator(node, cache[node.parent_id]);
    }
    if (node.children && node.children.length) {
      cache[node.id] = node;
      for (let i = 0; i < node.children.length; i += 1) {
        // @ts-ignore
        stack.push(node.children[i]);
      }
    }
  }
  console.log(stack, 'stack-------------')
  return null;
};
const operateListNodeById = (id: string | number, list: any): any => {
  for (let i = 0; i < list.length; i++) {
    if (list[i].id == id) {
      return list[i];
    }
  }
  return null;
};
const removeTreeNodeById =
  (id: string | number, tree: any): any => {
    operateTreeNodeById(id, tree, ((currentNode, parentNode) => {
      if (parentNode && parentNode.children) {
        for (let i = 0; i < parentNode.children.length; i += 1) {
          // eslint-disable-next-line eqeqeq
          if (parentNode.children[i].id == id) {
            parentNode.children.splice(i, 1);
          }
        }
      }
    }));
    return tree;
  };
const getTreeNodeById = (id: string | number, tree: any): any => {
  const result = operateTreeNodeById(id, tree, (currentNode) => currentNode);
  return result;
};
const getRowdataById = (id: string | number, list: any): any => {
  const result = operateListNodeById(id, list);
  return result;
};
// eslint-disable-next-line max-len
const replaceTreeNodeById = (id: string | number, tree: any, newValue: any): any => operateTreeNodeById(id, tree, ((currentNode, parentNode) => {
  for (let i = 0; i < parentNode.children.length; i += 1) {
    // eslint-disable-next-line eqeqeq
    if (parentNode.children[i].id == id) {
      const { children } = parentNode.children[i];
      parentNode.children[i] = newValue;
      parentNode.children[i].isDetailed = true;
      parentNode.children[i].children = children; // 服务器只返回当前的节点的详细信息，不包含子结果，所以需要保留原来的子节点
      return parentNode.children[i];
    }
  }
  return null;
}));

export {
  getTreeParentKey,
  getTreeParent,
  getTreeItem,
  getChildrenTreeItem,
  searchTreeNodes,
  addTreeNode,
  removeTreeNodeById,
  replaceTreeNodeById,
  getTreeNodeById,
  getRowdataById,
};
