import request from '@/utils/request';

export async function queryTree(params: any) {
  // return request('/api/specdatas/tree/simple', {
  return request('/api/specdatas/tree', {
    method: 'get',
    params,
  });
}

export async function queryTreeDetail(params: any) {
  return request('/api/specdatas/treedetail', {
    method: 'get',
    params,
  });
}

export async function addTreeNode(params: any) {
  return request('/api/specdatas/tree/add', {
    method: 'post',
    params,
  });
}
export async function pasteTreeNodes(params: any) {
  return request('/api/specdatas/tree/paste', {
    method: 'post',
    params,
  });
}

export async function changeTreeNodePos(params: any) {
  return request('/api/specdatas/treenode/changeposition', {
    method: 'post',
    params,
  });
}
export async function removeTreeNode(params: any) {
  return request('/api/specdatas/tree/remove', {
    method: 'post',
    params,
  });
}

export async function saveTreeNodeDetail(params: any) {
  // console.log(params)
  return request('/api/specdatas/treedetail/save', {
    method: 'post',
    params,
  });
}

export async function addTreeNodeRules(params: any) {
  return request('/api/specdatas/addrules', {
    method: 'post',
    params,
  });
}

export async function deleteNodeRule(params: any) {
  return request('/api/specdatas/rule/delete', {
    method: 'post',
    params,
  });
}

export async function deleteFileItem(params: any) {
  return request('/api/fileupload/delete', {
    method: 'post',
    params,
  });
}

export async function deleteDetailVehicle(params: any) {
  return request('/api/specdatas/deletedetailvehicle', {
    method: 'post',
    params,
  });
}
