/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/no-mutable-exports */
let CURRENT: string | string[] = 'NULL';

type CurrentAuthorityType = string | string[] | (() => typeof CURRENT);
/**
 * renderAuthorize声明一个一般函数，它接参类型参数类型为T, 然后返回一个函数, 返回的函数，
 * 接受的参数为currentAuthority, 返回的类型为T
 * 其它T为component/Authorized.tsx组件, 而参数currentAuthority则为utils中的getAuthority（返回一个string或者string[])
 * 并赋值给CURRENT变量, Authorized组件，在render时，会调用check方法，而check方法会调用这个变量，获取current中的值
 * use  authority or getAuthority
 * @param {string|()=>String} currentAuthority
 */
const renderAuthorize = <T>(Authorized: T): ((currentAuthority: CurrentAuthorityType) => T) => (
  currentAuthority: CurrentAuthorityType,
): T => {
  if (currentAuthority) {
    if (typeof currentAuthority === 'function') {
      CURRENT = currentAuthority();
    }
    if (
      Object.prototype.toString.call(currentAuthority) === '[object String]' ||
      Array.isArray(currentAuthority)
    ) {
      CURRENT = currentAuthority as string[];
    }
  } else {
    CURRENT = 'NULL';
  }
  return Authorized;
};

export { CURRENT };
export default <T>(Authorized: T) => renderAuthorize<T>(Authorized);
