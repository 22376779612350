import { TreeItem } from './databases.d';
import { Effect } from 'dva';
import {
  addTreeNode,
  addTreeNodeRules,
  changeTreeNodePos,
  deleteFileItem,
  deleteNodeRule, pasteTreeNodes,
  queryTree,
  queryTreeDetail,
  removeTreeNode,
  saveTreeNodeDetail,
  deleteDetailVehicle,
} from '@/services/databases';
import { Reducer } from 'redux';
import {
  addTreeNode as addTreeNodeUtil,
  getTreeNodeById,
  removeTreeNodeById,
  replaceTreeNodeById,
} from '@/utils/tree';
import { message } from 'antd';
// @ts-ignore
import { removeDetailVehicleById } from '@/utils/vehicles';

export interface StateType {
  tree: Partial<TreeItem> | null;
}

export interface ModelType {
  namespace: string;
  state: StateType;
  effects: {
    fetch: Effect;
    addTreeNode: Effect;
    removeTreeNode: Effect;
    // DetailForm.tsx获取详细信息
    fetchDetail: Effect;
    saveDetail: Effect;
    uploadFile: Effect;
    removeFileItem: Effect;
    addRules: Effect;
    deleteRule: Effect;
    changePosition: Effect;
    deleteDetailVehicle: Effect;
    // copy and paste
    pasteTreeNodes: Effect;
  };
  reducers: {
    save: Reducer<StateType>;
    addedTreeNode: Reducer;
    removedTreeNode: Reducer;
    getDetailed: Reducer; // 获取到node详细信息, 对应于DetailForm
    editNode: Reducer; // 添加文件
    changePos: Reducer;
    removedetailvehicle:Reducer;
  };
}

const Model: ModelType = {
  namespace: 'databases',
  state: {
    tree: {
      id: 0,
      name: 'Root',
    },
  },
  effects: {
    * fetch({ payload, callBack }, { call, put }) {
      const response = yield call(queryTree, payload);
      yield put({
        type: 'save',
        payload: response,
      });
      if (response && response.success) {
        callBack(response.data)
      }
    },
    * fetchDetail({ payload, success }, { call, put }) {
      const response = yield call(queryTreeDetail, payload);
      if (response.data.length > 0) {
        if (success) success(response.data[0]);
        yield put({
          type: 'getDetailed',
          payload: response.data,
        });
      }
    },
    * addTreeNode({ payload, callback, failure, success }, { call, put }) {
      const response = yield call(addTreeNode, payload);
      if (response.success) {
        if (success) success(response.data);
        yield put({
          type: 'addedTreeNode',
          payload: response,
        });
      } else if (failure) failure(response.error);
      if (callback) callback();
    },
    * removeTreeNode({ payload, success }, { call, put }) {
      const response = yield call(removeTreeNode, payload);
      if (response.success) {
        if (success) success(response.data);
        yield put({
          type: 'removedTreeNode',
          payload: payload.id,
        });
      }
    },
    /**
     * 添加 Rules
     */
    * addRules({ payload }, { call, put }) {
      const response = yield call(addTreeNodeRules, payload);
      if (response.success) {
        yield put({
          type: 'editNode',
          payload: {
            type: 'addRules',
            id: payload.from_id,
            rules: response.data,
          },
        });
        response.data.forEach((item: any) => message.success(`${item.target_name} added success!`));
      }
    },
    * deleteRule({ payload }, { call, put }) {
      const response = yield call(deleteNodeRule, payload);
      if (response.success) {
        message.success(`${payload.target_name} deleted`);
        yield put({
          type: 'editNode',
          payload: {
            id: payload.id,
            source_id: payload.source_id,
            target_id: payload.target_id,
            type: 'deleteRule',
          },
        });
      }
    },
    /**
     * 保存树详细信息
     */
    * saveDetail({ payload, success }, { call, put }) {
      const response = yield call(saveTreeNodeDetail, payload);
      // console.log('payload:', payload);
      // console.log('response:', response);
      if (response.success) {
        if (success) success(response.data);
        yield put({
          type: 'getDetailed',
           payload: [response.data],
        });
      }
    },
    * uploadFile({ payload }, { put }) {
      yield put({
        type: 'editNode',
        payload: {
          ...payload,
          type: 'addFile',
        },
      });
    },
    * removeFileItem({ payload, success }, { call, put }) {
      const {
        file: { uid, target_id: targetId },
      } = payload;
      const response = yield call(deleteFileItem, {
        uid,
      });
      console.log(response, 'response')
      if (response.success) {
        if (success) success();
        yield put({
          type: 'editNode',
          payload: {
            id: targetId,
            uid,
            type: 'removeFile',
          },
        });
      }
    },
    // 对目录进行排序
    * changePosition({ payload }, { call, put }) {
      yield put({
        type: 'changePos',
        payload: {
          id: payload.id,
          pos: payload.pos,
        },
      });
      const response = yield call(changeTreeNodePos, {
        ...payload,
      });
      if (response && response.success) {
        console.log(response);
      }
    },
    * pasteTreeNodes({ payload }, { call, put }) {
      const response = yield call(pasteTreeNodes, {
        ...payload,
      });
      if (response) {
        yield put({
          type: 'addedTreeNode',
          payload: {
            data: response.data,
          },
        });
      }
    },
    * deleteDetailVehicle({ payload, success }, { call, put }) {
      const response = yield call(deleteDetailVehicle, payload);
      if (response.success) {
        // if (success) success(response.data);
        yield put({
          type: 'removedetailvehicle',
          payload: payload.id,
        });
      }
    },
  },
  reducers: {
    save(state, action) {
      console.log(action.payload.data)

      return {
        ...state,
        tree: action.payload.data,
      };
    },
    addedTreeNode(state, action) {
      const { data } = action.payload;
      let parentId = 0;
      if (Array.isArray(data) && data.length > 0) {
        parentId = data[0].parent_id;
      } else {
        parentId = data.parent_id;
      }
      const stateTree = addTreeNodeUtil(parentId, data, state.tree);
      return {
        ...state,
        tree: stateTree,
      };
    },
    removedTreeNode(state, action) {
      const stateTree = removeTreeNodeById(action.payload, state.tree);

      return {
        ...state,
        tree: stateTree,
      };
    },
    getDetailed(state, action) {
      const { payload } = action;
      const [node] = payload;
      console.log('payload', payload[0])
      // console.log('state', payload[0].vehicles)
      // let { payload: { vehicles: id } } = action;
      // let initVehList: any = [];
      // const aa = payload[0].vehicles;
      // for (let i = 0; i < aa.length; i++) {
      //   initVehList.push({ id: aa[i].id })
      // }
      // id = initVehList;
      if (!node) {
        return {
          ...state,
        };
      }

      replaceTreeNodeById(node.id, state.tree, node);
      return {
        ...state,
        tree: state.tree,
      };
    },
    editNode(state, action) {
      const {
        payload: { id, file, type, uid, source_id: sourceId, target_id: targetId, rules },
      } = action;
       console.log(state, id, 'state-----base-----')
      console.log(action, 'action----base------')
      const node = getTreeNodeById(id, state.tree);
      switch (type) {
        case 'addFile':
          console.log(file, 'addfiles', node)
          if (file) {
            // 添加图片，会传入{id: 12, fileList: []}
            node.files = [...node.files, file];
            node.uploadFiles = true;
          }
          break;
        case 'removeFile':

          if (uid && node && node.files) {
            // console.log(node.files.filter((item: any) => item.uid !== uid),'ddddd')
            node.files = node.files.filter((item: any) => item.uid !== uid);
            console.log(node, 'del_after_node----------------')
          }
          break;
        case 'addRules':
          if (rules && rules.length > 0) {
            rules.forEach((item: any) => {
              node.rules = [...node.rules, item];
              // 修改对应treeNode
              if (item.target_id) {
                const toNode = getTreeNodeById(item.target_id, state.tree);
                toNode.rules = [...toNode.rules, item];
              }
            });
          }
          break;
        case 'deleteRule':
          if (sourceId && targetId) {
            node.rules = node.rules.filter(
              (item: { source_id: any; target_id: any }) =>
                !(item.source_id === sourceId && item.target_id === targetId),
            );
            // 修改对应node的rules
            // eslint-disable-next-line eqeqeq
            if (targetId == id) {
              // 被其它节点选择
              // 针对当前node,  保留当前node.rules下不等于sourceId和targetId的键
              const toNode = getTreeNodeById(sourceId, state.tree);
              if (toNode.rules) {
                toNode.rules = toNode.rules.filter(
                  (item: { source_id: any; target_id: any }) =>
                    !(item.source_id === sourceId && item.target_id === targetId),
                );
              }
              // toNode.isDetailed = false; // toNode重新加载detail
            } else {
              // 针对当前node,  保留当前node.rules下不等于sourceId和targetId的键
              const toNode = getTreeNodeById(targetId, state.tree);
              if (toNode.rules) {
                toNode.rules = toNode.rules.filter(
                  (item: { source_id: any; target_id: any }) =>
                    !(item.source_id === sourceId && item.target_id === targetId),
                );
              }
              // toNode.isDetailed = false; // toNode重新加载detail
            }
          }
          break;
        default:
          console.log('no action');
      }

      // replaceTreeNodeById(node.id, state.tree, node);
      return {
        ...state,
        tree: state.tree,
      };
    },
    changePos: (state, action) => {
      const { id, pos } = action.payload;
      const node = getTreeNodeById(id, state.tree);
      if (node && pos >= 0) {
        const parentNode = getTreeNodeById(node.parent_id, state.tree);
        if (parentNode && parentNode.children) {
          const foundIndex = parentNode.children.findIndex((item: { id: any }) => item.id == id);
          if (foundIndex > -1) {
            parentNode.children.splice(foundIndex, 1);
          }
          parentNode.children.splice(pos, 0, node);
        }
      }

      return {
        ...state,
      };
    },
    removedetailvehicle(state, action) {
       const stateTree = removeDetailVehicleById(action.payload, state.tree);
       console.log(stateTree)
      return {
        ...state,
        // vehkeys: vehkeys.filter(key => key !== k),
      };
    },
  },
};
export default Model;
