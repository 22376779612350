// use localStorage to store the authority info, which might be sent from server in actual project.

export function getAuthority(str?: string): string | string[] {
  // return localStorage.getItem('antd-pro-authority') || ['admin', 'user'];
  const expireStr = localStorage.getItem('expire');
  if (expireStr) {
    const current = new Date();
    const expire = new Date(expireStr);
    if (current.getTime() < expire.getTime()) {
      const role = localStorage.getItem('role');
      if (role) {
        return [role];
      }
    }
  }
  return '';
}
